.inspector {
  margin-top: var(--spacing-l);
}

.layout {
  display: flex;
  position: absolute;
  inset: 0;
}

.layoutSidebar {
  border-right: 1px solid var(--border-color);
  width: 300px;
}

.layoutMain {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.layoutList {
  flex: 1;
  position: relative;
  padding: 30px;
  width: 100%;
  box-sizing: border-box;
}

.filter {
  all: inherit;
  margin-right: var(--spacing-l);
  cursor: pointer;
}

.filter svg {
  display: inline-block;
  vertical-align: middle;
  fill: var(--light-body-color);
}

.item {
  all: inherit;
  display: block;
  box-sizing: border-box;
  background: rgba(var(--color-rgb-components), 0.1);
  font-size: var(--font-size-s);
  padding: var(--spacing-s);
  line-height: 1;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  margin: 2px;
}

.item.as-inline-block{
  display: inline-block;
  margin: var(--spacing-s);
}

.item:hover {
  background: rgba(var(--color-rgb-components), 0.2);
}

.appH1 {
  font-size: var(--font-size-xxl);
  font-weight: bold;
}

.appH2 {
  font-size: var(--font-size-xl);
  font-weight: bold;
}

.appH3 {
  font-size: var(--font-size-l);
  font-weight: bold;
}

.ruler {
  height: 1px;
  background-color: var(--primary-color);
  opacity: 0.2;
  margin-top: var(--spacing-m);
  margin-bottom: var(--spacing-xl);
}