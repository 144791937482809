.root {
    max-height: calc(100vh - 106px);
    overflow: auto;
  }
  
  .options {
    display: flex;
    padding: var(--spacing-m) var(--spacing-m) var(--spacing-m) 0;
    justify-content: space-between;
    border-bottom: 1px solid var(--border-color);
  }
  
  .options > * {
    margin-left: var(--spacing-m);
  }
  
  .model {
    padding: var(--spacing-s) var(--spacing-l) var(--spacing-s) var(--spacing-m);
    border-bottom: 1px solid var(--border-color);
    display: flex;
  }
  
  .toggler {
    display: block;
    cursor: pointer;
    color: var(--light-body-color);
    flex: 1;
  }
  
  .togglerActive {
    color: var(--base-body-color);
  }
  
  .toggler input[type="checkbox"] {
    accent-color: white;
  }
  
  .color {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background-color: rgba(var(--color-rgb-components), 0.5);
    outline: 1px solid var(--border-color);
    border: 2px solid white;
  }
  
  .model:hover .color, .modelActive .color {
    background-color: rgba(var(--color-rgb-components), 0.7);
  }
  
  .modelActive {
    background: var(--light-color);
    font-weight: bold;
  }